import React, { useState, useEffect, useContext } from "react";
import ThemeContext, { useThemeContext } from "../../Context/ThemeContext";
import styled from "styled-components";
import Select from "react-select";
import DataTable, { createTheme } from "react-data-table-component";
import "./Wallet.css";
import axios from "axios";
import ConvertImg from "../../images/Group 42449.png";
import { API_URLs } from "../../Utils/ApiUrls";
import { darkTheme, lightTheme } from "../../Theme/theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomModal from "../../hooks/CustomModal";
import { useWalletContextHook } from "../../Context/WalletContext";

const Input = styled.input`
  border: none;
  border-bottom: 1px solid grey;
  outline: none;
  font-size: 16px;
  color: grey;
  font-weight: 600;
  width: 65%;
  background: transparent;
`;
const Button = styled.button`
  background: #1476ff;
  border: none;
  color: white;
  border-radius: 20px;
  padding: 5px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;
const ImgDiv = styled.div`
  background: #defffa;
  padding: 7px;
  border: none;
  border-radius: 10px;
`;
const Card = styled.div`
  border: ${({ theme }) => theme.border};
  box-shadow: ${({ theme }) => theme.boxshadow};
  padding: 12px;
  border-radius: 20px;
  background: ${({ theme }) =>
    theme.body == "#F7F9FC" ? "white" : theme.body};
`;
const conertTxnData = [
  {
    fromAmount: "0.002",
    fromCrypto: "btc",
    toAmount: "1.23",
    toCrypto: "eth",
    date: "12-04-2023",
  },
  {
    fromAmount: "0.002",
    fromCrypto: "btc",
    toAmount: "1.23",
    toCrypto: "eth",
    date: "12-04-2023",
  },
  {
    fromAmount: "0.002",
    fromCrypto: "btc",
    toAmount: "1.23",
    toCrypto: "eth",
    date: "12-04-2023",
  },
];
const column = [
  {
    name: "From Amount",
    selector: (row) => <div>{row.fromAmount}</div>,
  },
  {
    name: "From Crypto",
    selector: (row) => <div>{row.fromCrypto}</div>,
  },
  {
    name: "To Amount",
    selector: (row) => <div>{row.toAmount}</div>,
  },
  {
    name: "To Crypto",
    selector: (row) => <div>{row.toCrypto}</div>,
  },
];

const currencyOptions = [
  {
    value: "BTC",
    label: "BTC",
    icon: "https://cryptologos.cc/logos/bitcoin-btc-logo.svg?v=014",
  },
  {
    value: "USDT",
    label: "USDT",
    icon: "https://cryptologos.cc/logos/tether-usdt-logo.svg?v=014",
  },
  {
    value: "USDC",
    label: "USDC",
    icon: "https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=014",
  },
  {
    value: "ETH",
    label: "ETH",
    icon: "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=014",
  },
  {
    value: "MATIC",
    label: "MATIC",
    icon: "https://cryptologos.cc/logos/polygon-matic-logo.svg?v=014",
  },
];
export default function Convert() {
  const { getWallet, arr, setShouldReload } = useWalletContextHook();
  const { isDarkTheme } = useThemeContext();
  const [isFill, setisFill] = useState(false);
  const [convertFrom, setconvertFrom] = useState({
    value: "ETH",
    label: "ETH",
    icon: "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=014",
  });
  const [convertFromAmount, setconvertFromAmount] = useState("");
  const [convertTo, setconvertTo] = useState({
      value: "BTC",
      label: "BTC",
      icon: "https://cryptologos.cc/logos/bitcoin-btc-logo.svg?v=014",
    });
  const [convertToAmount, setconvertToAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [exchangeRateValue, setExchangeRateValue] = useState("");
  const [minUSDTconvertedRate, setMinUSDTconvertedRate] = useState("");
  const [maximumBalance, setMaximumBalance] = useState(0);
  const [convertedRate, setConvertedRate] = useState(0)
  const [navLink, setNavLink] = useState('')
  const [allFees, setAllFees] = useState({})

  const handleShowModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // changly
  const [currencyList, setCurrencyList] = useState([]);

  const GetCurrency = () => {
    axios
      .get(API_URLs.getChangelyCurrency, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((res) => {
        let changlyCryptoList =
          res.data?.length > 0 &&
          res.data.map((currencyObject) => {
            return {
              ...currencyObject,
              value: currencyObject.ticker,
              label: `${
                currencyObject.name != "MATICPOLYGON"
                  ? currencyObject.name
                  : "MATIC"
              }`,
              name: currencyObject?.name,
              image: currencyObject?.image,
            };
          });
          setCurrencyList(changlyCryptoList || []);
      })
      .catch((error) => {});
  };

  // const convertHandle = () => {
  //   setisFill(true);
  //   axios
  //     .post(
  //       API_URLs.getChangelyCreateTxn,
  //       {
  //         hash: localStorage.getItem("changely-hash"),
  //       },
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       // toast.success('Conversion successful!');
  //       console.log("convertHandle", res);
  //       handleShowModal("The conversion has been completed successfully.");
  //       setShowModal(true);
  //       localStorage.removeItem("changely-hash");
  //       // setconvertToAmount(res.data[0]?.amountTo)
  //     })
  //     .catch((error) => {
  //       console.log("convertHandleError", error);
  //       // toast.error('Conversion failed!');
  //       handleShowModal(
  //         "Conversion failed because there was not enough balance available."
  //       );
  //       setconvertToAmount("");
  //     })
  //     .finally(() => {
  //       setisFill(false);
  //     });
  // };

  // 3. Have to send amount in string
  const exchangeRate = async (currency, balance, crypto, funCallFor='general') => {
    try {
      if (currency && balance) {
        let res = await axios.get(
          API_URLs.cryptoToFiat +"?from="+currency+"&amount="+balance +"&target="+crypto
        );
        funCallFor === 'forMinValue' && setExchangeRateValue(res?.data);
        setMinUSDTconvertedRate(res?.data);
        return res.data;
      }
    } catch (error) {}
  };

  // 2. have send amount in string
  const convertCrypto = (from, to, amount) => {
    if (from && to) {
      axios.get(API_URLs.cryptoToFiat +"?from="+from+"&amount="+1 +"&target="+to, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          setConvertedRate(res?.data)
          // handleShowModal(res.)
          // setconvertToAmount(res?.data?.conversionrate);
          // localStorage.setItem("changely-hash", res?.data?.hash);
        })
        .catch((error) => {
          // handleShowModal(error?.response?.data?.message);
          // setconvertToAmount("");
          // const errorMsg = error.response?.data?.message || "An error occurred";
          // const minimalAmount = parseFloat(errorMsg.match(/(\d+(\.\d+)?)/)[0]).toFixed(2);
          // const formattedErrorMsg = errorMsg.replace(/(\d+(\.\d+)?)/, minimalAmount);
          // toast.error(formattedErrorMsg);
        });
    }
  };

  const styles = {
    control: (styles) => ({
      ...styles,
      background: "transparent",
      borderRadius: "52px",
      width: "100%",
      height: "50px",
      color: "white",
      boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
      border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`,
      "&:hover": {
        border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        isDarkTheme && state.isHovered
          ? "#302F30"
          : `${({ theme }) => theme.body}`,
      ":hover": {
        backgroundColor: `${isDarkTheme ? "#302F30" : "#D9EDE7"}`,
      },
      color:
        isDarkTheme && state.isSelected
          ? "#1476FF"
          : `${({ theme }) => theme.text}`,
    }),
    indicatorSeparator: () => ({
      all: "unset",
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      color: isDarkTheme ? "white" : "black",
      "div:nth-child(2)": {
        color: isDarkTheme ? "white" : lightTheme.text,
      },
    }),
    menu: (styles) => ({
      ...styles,
      background: isDarkTheme ? "rgb(27, 27, 28)" : "white",
      color: isDarkTheme ? "white" : lightTheme.text,
    }),
  };

  createTheme("solarized", {
    background: {
      default: `${isDarkTheme ? "#0c0c0c" : "white"}`,
    },
    action: {
      hover: "rgba(0,0,0,.08)",
    },
    text: {
      secondary: `${isDarkTheme ? "white" : "black"}`,
    },
  });

  const customStyles = {
    header: {
      style: {
        fontSize: "20px",
        color: "#0B0E2C",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
      },
    },
    headRow: {
      style: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "12px",
        ...(isDarkTheme
          ? { borderTop: "1px solid #302f30" }
          : { border: "1px solid lightgray" }),
        color: `${isDarkTheme ? "#e9e9e9" : "#6F7182"}`,
        backgroundColor: `${isDarkTheme ? "#0c0d0d" : "#F7F9FC"}`,
        textTransform: "uppercase",
      },
    },
    rows: {
      style: {
        color: `${isDarkTheme && "white"}`,
        backgroundColor: `${isDarkTheme && "rgba(8, 9, 14, 0.96)"}`,
        borderTop: "1px solid lightgray",
        "&:not(:last-of-type)": {
          borderBottom: "none",
        },
      },
    },
    headCells: {
      style: {
        display: "flex",
        alignItems: "center",
      },
    },
    cells: {
      style: {
        display: "flex",
        alignItems: "center",
      },
    },
  };


  //1. have to send amount in string
  const swapingCurrency = (from, to, amount, callType='fromCalling') => {
    if (from && to && amount) {
      axios
        .get(`${API_URLs.getswapConversion}/${from}/${to}/${amount}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          setAllFees(res?.data?.data)
          if(callType==='toCalling'){
            setconvertFromAmount(Number(res?.data?.data?.conversionrate).toFixed(13));
          }else{
            setconvertToAmount(Number(res?.data?.data?.conversionrate).toFixed(13));
          }
        })
        .catch((error) => {
          !error?.response?.data?.message?.includes(0.000001) &&
            handleShowModal(error?.response?.data?.message);
          setconvertToAmount("");
        });
    }
  };

  const getswapConversion = (from, to, amount) => {
    setisFill(true);
    if (Number(minUSDTconvertedRate?.convertedRate) >= 5) { 
      axios
        .post(`${API_URLs.swapCryptoCurrency}`,
          {
            amount: convertFromAmount,
            from: convertFrom?.value?.toUpperCase(),
            to: convertTo?.value?.toUpperCase(),
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {
          handleShowModal("The conversion has been completed successfully.");
          setShowModal(true);
          setisFill(false);
          setNavLink('#balance')
          setShouldReload(prev=>!prev)
        })
        .catch((err) => {
          handleShowModal("Conversion failed because there was not enough balance available.");
          setisFill(false);
          // setconvertToAmount("");
        })
        .finally(() => {
          setisFill(false);
        });
    } else {
      handleShowModal(`You must have a minimum balance equivalent to 5 USDC to convert.`);
      setShowModal(true);
      setisFill(false);
    }
  };

  const convertStateHandle = () => {
    let from = convertFrom;
    let to = convertTo;
    // swapingCurrency(from?.value, to?.value, convertFromAmount);
    swapingCurrency(to?.value, from?.value, convertFromAmount);
    setconvertFrom(to);
    setconvertTo(from);
    exchangeRate("USDC", 5, convertTo?.value, 'forMinValue');
    convertCrypto(convertTo?.value,convertFrom?.value,1)
  };

  const getTokenIcon = (name) => {
    return currencyOptions.find((data) => data.value === name)?.icon;
  };

  const handleMinimumAmount = async () => {
    const maxValue = await getMaxBalance(convertFrom?.value)
    if(maxValue?.balance < exchangeRateValue?.convertedRate){
      setconvertFromAmount(null);
      handleShowModal('There is Insufficient balance. You must have a minimum balance equivalent to 5 USDT to convert.')
      return;
    }else{  
      const responseData = await exchangeRate(convertFrom?.value, exchangeRateValue?.convertedRate, 'USDC');
      swapingCurrency(convertFrom?.value, convertTo?.value, exchangeRateValue?.convertedRate);
      setMinUSDTconvertedRate(responseData);
      // let fakeAmount = '0.011780411081111608883392581';
      // const sliceStrAmount = fakeAmount?.toString()?.length > 16 ? fakeAmount?.toString()?.slice(0, 17) : fakeAmount;
      // let incrementedChar = (parseInt(sliceStrAmount[15]) + 1).toString();
      // let newAmount = sliceStrAmount.slice(0, 15) + incrementedChar + sliceStrAmount.slice(16);
      // console.log('newAmount::', newAmount);
      setconvertFromAmount(exchangeRateValue?.convertedRate);
    }
  };

  const getMaxBalance = (currencyType) => {
    const value = arr?.find((tokenList)=>{
      if(currencyType===tokenList?.currency){
        setMaximumBalance(tokenList)
        return tokenList
      }
    })
    return value
  };

  const handleMaximumAmount=()=>{
    const maxValue = getMaxBalance(convertFrom?.value)
    if(maxValue?.balance < exchangeRateValue?.convertedRate){
      handleShowModal('There is Insufficient balance. You must have a minimum balance equivalent to 5 USDT to convert.')
      return ;
    }else{
      swapingCurrency(convertFrom?.value, convertTo?.value, maxValue?.balance)
      if(maxValue?.balance <=0){
        setconvertFromAmount(0)
      }else{
        setconvertFromAmount(maxValue?.balance)
      }
      exchangeRate(convertFrom?.value, maxValue?.balance, "USDC");
    }
  }
  
  useEffect(() => {
    getWallet();
    exchangeRate("USDC", 5, convertFrom?.value, 'forMinValue');
    convertCrypto(convertFrom?.value,convertTo?.value,1)
    const balance = getMaxBalance('ETH')
    // handleMaximumAmount(balance?.balance)
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="row m-0 d-flex justify-content-center align-items-center">
        <Card className="card col-md-7 col-sm-12 d-flex flex-column h-100 px-4 py-3">
          <h1 className="convertTitle text-center">
            Convert <span>{convertFrom?.value || "BTC"}</span> to{" "}
            <span>{convertTo?.value || "USDT"}</span>
          </h1>
          <p className="mb-0 convert-para">You are Converting</p>
          <div className="d-flex pt-3 pb-2 input-dev">
            <Input
              className="flex-grow-1 "
              value={convertFromAmount}
              placeholder="Enter Amount"
              maxLength={25}
              onChange={(e) => {
                const regex = new RegExp(/^\d*\.?\d*$/);
                if (regex.test(e.target.value) || e.target.value === "") {
                  setconvertFromAmount(e.target.value);
                  swapingCurrency(convertFrom?.value, convertTo?.value, e.target.value);
                  exchangeRate(convertFrom?.value, e.target.value, "USDC");
                }
              }}
            />
            <div className="select-token-option d-flex align-items-center px-2">
              {convertFrom?.value && (
                <div className="selectedTokenIconNname d-flex align-items-center gap-1">
                  <img src={getTokenIcon(convertFrom?.value)} />
                  <span>{convertFrom?.value}</span>
                </div>
              )}
              {console.log('convertFrom?.value',convertFrom?.value)}
              <Select
                className="select selectCurrency"
                isSearchable={false}
                value={convertFrom}
                options={currencyOptions?.filter(
                  (data) => data.value !== convertTo?.value
                )}
                styles={styles}
                onChange={(selectedOption) => {
                  setconvertFrom(selectedOption);
                  swapingCurrency(selectedOption.value, convertTo?.value,convertFromAmount);
                  getMaxBalance(selectedOption?.value)
                  exchangeRate("USDC", 5, selectedOption?.value, 'forMinValue');
                  convertCrypto(selectedOption?.value, convertTo?.value, convertFromAmount);
                }}
              />
            </div>
          </div>
          
          {
           !(convertFromAmount?.toString()?.length>17) && ( convertFromAmount < exchangeRateValue?.convertedRate ) && (convertFromAmount !== "" && convertFromAmount != null) && 
            <p className="text-danger input-dev">
              Amount must be greater than or equal to {exchangeRateValue?.convertedRate?.toFixed(7)} { exchangeRateValue?.target}
            </p>
          }
          
          <div className="input-dev">
            <p className="mt-1 mb-0 min_amoutn">Min:{" "}{exchangeRateValue?.convertedRate ? exchangeRateValue?.convertedRate?.toFixed(6) :0}</p>
          </div>
          <div className="group_button input-dev mb-3 mt-2 d-flex align-items-center justify-content-between gap-2 gap-md-5">
            <button onClick={handleMinimumAmount}>Minimum Amount</button>
            <button onClick={handleMaximumAmount}>Maximum Amount</button>
          </div>
        </Card>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <ImgDiv onClick={convertStateHandle} className="text-center">
          <img src={ConvertImg} alt="Convert" width="60%" />
        </ImgDiv>
      </div>
      <div className="m-0 row d-flex justify-content-center align-items-center">
        <Card className="card col-md-7 col-sm-12 d-flex flex-column h-100 px-4 py-3">
          <p className="mb-0 convert-para">You will Receive</p>
          <div className="d-flex justify-content-between pt-3 pb-2 input-dev">
            <Input
              className="flex-grow-1"
              value={convertToAmount}
              placeholder="You'll Receive Amount"
              onChange={(e) => {
                const regex = new RegExp(/^\d*\.?\d*$/);
                if (regex.test(e.target.value) || e.target.value === "") {
                  setconvertToAmount(e.target.value);
                  exchangeRate(convertFrom?.value, e.target.value, "USDC");
                  e.target.value > 0.00001 && swapingCurrency(convertTo?.value,convertFrom?.value, e.target.value, 'toCalling');
                }
              }}
              // disabled
            />
            <div className="select-token-option d-flex align-items-center px-2">
              {convertTo?.value && (
                <div className="selectedTokenIconNname d-flex align-items-center gap-2">
                  <img src={getTokenIcon(convertTo?.value)} alt="token" />
                  <span>{convertTo?.value}</span>
                </div>
              )}
              
              <Select
                className="select selectCurrency"
                value={convertTo}
                isSearchable={false}
                width="100%"
                options={currencyOptions?.filter((data) => {
                  return data.value !== convertFrom?.value;
                })}
                styles={styles}
                onChange={(selectedOption) => {
                  setconvertTo(selectedOption);
                  convertCrypto( convertFrom?.value, selectedOption?.value, convertFromAmount);
                  swapingCurrency(
                    convertFrom?.value,
                    selectedOption?.value,
                    convertFromAmount
                  );
                }}
              />
            </div>
          </div>
          <p className="mt-2">
            Exchange Rate (1{' '}{convertFrom?.value})={" "}
            {convertedRate?.convertedRate
              ? convertedRate?.convertedRate?.toFixed(6)
              : 0}{" "}
            {convertedRate?.target}
          </p>
          <p className="m-0">* The received amount includes the conversion fees.</p>
        </Card>
      </div>
      <div className="text-center mt-3">
        <Button disabled={isFill} onClick={() => getswapConversion()}>
          {isFill ? "converting..." : "Convert"}
        </Button>
      </div>
      {showModal && (
        <CustomModal
          show={showModal}
          handleClose={handleCloseModal}
          data={modalData}
          navigateLink={navLink}
        />
      )}
    </div>
  );
}
